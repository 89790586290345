import store from '@/store';
import { Component, Vue, Ref } from 'vue-property-decorator';
import ComponentController from '@/components/ComponentController';
import TextContent from '@/components/TextContent/TextContent.vue';
import JourneyControllerService from '@/services/JourneyControllerService';
import ComponentListModel from '@/models/Components/ComponentListModel';
import LooseObject from '@/models/Objects/LooseObject';
import { EventBus } from '@/utilities/eventBus/EventBus';
import MessageBubbleModel from '@/models/Components/MessageBubble/MessageBubbleModel';
import FormModel from '@/models/Objects/FormModel';
import Message from '@/models/Components/MessageBubble/Message';

@Component({
    name: 'Home',
})
export default class Home extends Vue {
    private form: LooseObject = {};
    private formNames: string[] = [];
    private formToSubmit: LooseObject = {};
    private formRules: LooseObject = {};
    private newUserMessage: MessageBubbleModel = { userMessage: false, elementId: '', messages: [], disabled: false };
    private currentFormName: string = '';
    private currentWorkflowName: string = '';
    private currentActionId?: number;
    private children: any[] = [];
    private paq = window._paq;
    private requestData: LooseObject = {};
    private previousForms: FormModel[] = [];
    private isEditForm: boolean = false;
    private journeyController = new JourneyControllerService();
    private controller = new ComponentController(this.journeyController);
    private initialTimeout: number = 10000;
    private timeout: number = this.initialTimeout;
    private pollTimeout!: NodeJS.Timeout;
    private sessionTimeout!: NodeJS.Timeout;

    public loginRequired(): boolean {
        return this.$msal.requiresLogin;
    }

    public clearForm() {
        // console.log(JSON.stringify( this.form))
        // console.log(JSON.stringify( this.children));
        this.form = {};
        this.children = [];
        this.currentFormName = '';
        this.currentWorkflowName = '';
        this.newUserMessage = { userMessage: false, elementId: '', messages: [], disabled: false };
        this.isEditForm = false;
    }

    public getComponentsToDisplay(formData: LooseObject): void {
        clearTimeout(this.sessionTimeout);
        clearTimeout(this.pollTimeout);
        store.commit('updateEditAvailableFlag', false);
        const route: LooseObject = this.$route?.query;
        route.clientUrl = document.location.origin + document.location.pathname;
        this.controller
            .getComponentTypes(this.currentFormName, this.currentWorkflowName, formData, this.currentActionId, route)
            .then((response) => {
                this.formNames.push(response.formName);
                response.components.forEach((r: ComponentListModel) => {
                    this.populateComponentProps(r);
                });
                this.form.submittedMessageText = response.submittedMessageText;
                this.currentFormName = response.formName;
                this.currentWorkflowName = response.workflowName;
                this.currentActionId = response.actionId;
                EventBus.$emit('update-menu-top', response.topMenu);
                EventBus.$emit('update-menu-side', response.sideMenu);
                this.previousForms = this.$store.state.formHistory;
                store.commit('updateEditAvailableFlag', true);
                if (response.noEdit) {
                    store.commit('updateEditAvailableFlag', false);
                }
                if (response.autoRefresh) {
                    this.poll();
                }
                if (response.returnHistory !== undefined && response.returnHistory === false) {
                    store.commit('updateFormHistory', []);
                    store.commit('updateMessages', []);
                }
                if (response.clearApplicant) {
                    this.$store.commit('updateApplicantIdentifier', undefined);
                }
            })
            .catch((err) => {
                this.controller.displayNewMessage('Oops! Something went wrong...');
                const errorText: ComponentListModel = {
                    component: TextContent,
                    ref: 'text',
                    elementId: 'error',
                    content: err,
                };
                this.formNames.push(errorText.elementId!);
                this.populateComponentProps(errorText);
                store.commit('updateEditAvailableFlag', true);
                this.$store.state.loading = false;
            }).finally(() => {
                this.updateRoute();
                if (this.children.length > 0 && this.children[0].props?.elementId) {
                    document.getElementById(this.children[0]?.props?.elementId)?.focus();
                }
                this.paq.push(['setCustomVariable', 1, 'deviceIdentifier',
                    this.$store.state.deviceIdentifier, 'visit']);
                this.paq.push(['setCustomVariable', 1, 'sessionIdentifier',
                    this.$store.state.sessionIdentifier, 'visit']);
                this.paq.push(['setCustomVariable', 1, 'applicantIdentifier',
                    this.$store.state.applicantIdentifier, 'visit']);
                this.paq.push(['setCustomVariable', 1, 'applicationIdentifier',
                    this.$store.state.applicationIdentifier, 'visit']);
                this.paq.push(['MediaAnalytics::scanForMedia']);
                this.paq.push(['FormAnalytics::scanForForms']);
                this.paq.push(['trackContentImpressionsWithinNode']);
                this.paq.push(['enableLinkTracking']);
                this.checkSession();
            },
            );
    }

    public updateRoute() {
        this.$router.replace({query: {}});
        this.$router.push({ query: Object.assign({}, this.$route.query, { form: this.currentFormName }) });
    }

    get loading() {
        return this.$store.state.loading;
    }

    protected mounted() {
        if (!this.loginRequired() || this.$msal.isAuthenticated) {
            this.setEventListeners();
            this.getComponentsToDisplay({});
        }
    }

    protected updated() {
        this.$nextTick(() => {
            EventBus.$emit('update-form-panel-height');
            EventBus.$emit('update-side-panel-scroll');
        });
    }

    private setEventListeners() {
        EventBus.$on('refresh', () => {
            this.clearForm();
            this.getComponentsToDisplay({
                reInitialise: true,
            });
        });
        EventBus.$on('resolveTrigger', (
            reInitialise?: boolean,
            data?: LooseObject,
        ) => {
            this.clearForm();
            this.getComponentsToDisplay({
                ...{
                        reInitialise,
                },
                ...data,
            });
        });
        EventBus.$on('submitForm', (messageId?: string) => {
            this.submitHiddenValue('submit', this.form.submittedMessageText || ' ', ' ');
            this.submitForm(messageId);
        });
        EventBus.$on('submitValue', (
            elementId: string,
            value: string | number,
            label?: string,
            formattedMessage?: string,
        ) => this.addValueToForm(elementId, value, label, formattedMessage));
        EventBus.$on('submitFile', (
            elementId: string,
            value: LooseObject,
            label?: string,
            formattedMessage?: string,
        ) => this.addValueToForm(elementId, value, label, formattedMessage));
        EventBus.$on('submitHiddenValue', (
            elementId: string,
            value: string | number,
            label: string,
        ) => this.submitHiddenValue(elementId, value, label));
        EventBus.$on('editPreviousFormData', (
            formName: string,
            workflowName: string,
            formData: LooseObject,
            messageId: string,
            actionId?: number,
        ) => this.displayPreviousForm(formName, workflowName, formData, messageId, actionId));
        EventBus.$on('cancelForm', (
            previousForm: LooseObject[],
            previousFormName: string,
            previousWorkflowName: string,
        ) => this.cancelForm(previousForm, previousFormName, previousWorkflowName));
    }

    private submitHiddenValue(elementId: string, value: string | number, label: string) {
        this.addValueToForm(elementId, value, label || '', '');
    }


    private checkSession(): void {
        clearTimeout(this.sessionTimeout);
        const sessionIdentifier = this.$store.state.sessionIdentifierCookie();
        if (sessionIdentifier === undefined) {
            this.$msal.signOut();
            store.commit('updateFormHistory', []);
            store.commit('updateMessages', []);
            this.controller.displayNewMessage('Your session has timed out');
            const sessionTimeout: ComponentListModel = {
                component: TextContent,
                ref: 'text',
                elementId: 'error',
                content: 'Your session timed out due to inactivity. Please restart your application by refreshing the page, or resume using the unique link sent to you.',
            };
            this.clearForm();
            this.populateComponentProps(sessionTimeout);
            this.updateRoute();
        } else {
            this.sessionTimeout = setTimeout(() => { this.checkSession(); }, this.initialTimeout);
        }
    }

    private poll(): void {
        clearTimeout(this.pollTimeout);
        const modified = this.$store.state.lastModifiedCookie();
        this.journeyController.poll()
            .then(() => {
                const lastModified = this.$store.state.lastModified;
                if (modified === undefined || lastModified === modified) {
                    this.timeout += 1000;
                } else {
                    this.currentFormName = '';
                    this.currentWorkflowName = '';
                    this.currentActionId = undefined;
                    this.children = [];
                    this.getComponentsToDisplay({
                        refresh: true,
                    });
                    this.timeout = this.initialTimeout;
                }
            })
            .catch();
        this.pollTimeout = setTimeout(() => { this.poll(); }, this.timeout);
    }

    private getUniqueMessages(messages: Message[]) {
        const uniqueMessages = [];
        for (let i = messages.length - 1; i >= 0; i--) {
              const element = messages[i];
              const labels: string[] = [];
              uniqueMessages.forEach((el) => {
                labels.push((el.label || '') + el.elementId);
              });
              if (!labels.includes((element.label || '') + element.elementId)) {
                uniqueMessages.push(messages[i]);
              }
            }
        uniqueMessages.reverse();
        return uniqueMessages;
    }

    private submitForm(messageId?: string) {
        EventBus.$emit('requestValue');
        EventBus.$emit('requestHiddenValues');
        const formWrapper: any = this.$refs['form-wrapper'];
        const formElement = document.getElementById(this.currentFormName);
        formWrapper.validate((valid: boolean) => {
            if (valid) {
                if (messageId) {
                    EventBus.$emit('disableMessage', messageId);
                }
                this.newUserMessage.messages = this.getUniqueMessages(this.newUserMessage.messages);

                const newMessage: MessageBubbleModel = {
                    userMessage: true,
                    elementId: `message-bubble-${store.state.messages.length}`,
                    formName: this.currentFormName,
                    workflowName: this.currentWorkflowName,
                    actionId: this.currentActionId,
                    formData: this.form,
                    messages: this.newUserMessage.messages,
                    disabled: false,
                };
                this.paq.push(['FormAnalytics::trackFormSubmit', formElement]);
                this.paq.push(['FormAnalytics::trackFormConversion', formElement]);
                if (newMessage.messages.length > 0) {
                    store.commit('addMessage', newMessage);
                }
                this.previousForms
                    .filter((form) =>
                        form.name === this.currentFormName &&
                        form.workflow === this.currentWorkflowName &&
                        (this.currentActionId ?? 0) === (form.actionId ?? 0),
                    )
                    .map((form) => form.body = this.formToSubmit);
                store.commit('updateFormHistory', this.previousForms);
                EventBus.$emit('renderNewMessage', true);
                this.children = [];
                this.getComponentsToDisplay(this.formToSubmit);
                this.formToSubmit = {};
                this.form = {};
                this.newUserMessage = { userMessage: false, elementId: '', messages: [], disabled: false };

            } else {
                this.formToSubmit = {};
                this.paq.push(['FormAnalytics::trackFormSubmit', formElement]);
                return false;
            }
        });
    }

    private addValueToForm(
        elementId: string,
        value: string | number | LooseObject,
        label?: string,
        formattedMessage?: string,
    ) {
        this.formToSubmit[elementId] = value;
        if (formattedMessage || (value !== undefined && String(value) !== '' && label !== '')) {
            this.newUserMessage.messages.push({
                elementId,
                value,
                displayValue: formattedMessage,
                label,
            });
        }
    }

    private isInt(value: any): boolean {
        const x = parseFloat(value);
        /* tslint:disable-next-line */
        return !isNaN(value) && (x | 0) === x;
    }

    private displayPreviousForm(
        formName: string,
        workflowName: string,
        formData: LooseObject,
        messageId: string,
        actionId?: number,
    ) {
        const formToDisplay = this.previousForms.find((form) =>
            form.name === formName && form.workflow === workflowName,
        );
        if (formToDisplay === undefined) {
            // alert(`form: ${formName} was not found in the previous forms`);
            return;
        } else {
            const lastForm = this.children;
            const lastFormName = this.currentFormName;
            const lastWorkflowName = this.currentWorkflowName;
            const lastActionId = this.currentActionId;
            this.clearForm();
            // console.log(JSON.stringify(this.children));
            this.isEditForm = true;
            const components = this.controller.extractComponents(formToDisplay.formFields);
            components.forEach((comp) => {
                if (comp.ref === 'input_submit') {
                    comp.editedMessageId = messageId;
                    comp.previousForm = lastForm;
                    comp.previousFormName = lastFormName;
                    comp.previousWorkflowName = lastWorkflowName;
                    comp.previousActionId = lastActionId;
                    comp.isEdit = true;
                }
                // console.log(JSON.stringify(comp));
                const valueToEdit = formData[`${comp.elementId}`];
                this.populateComponentProps(comp, valueToEdit);
            });
            // console.log(JSON.stringify(this.children));
            this.currentFormName = formName;
            this.currentWorkflowName = workflowName;
            this.currentActionId = actionId;
            this.updateRoute();
            store.commit('updateEditAvailableFlag', false);
            EventBus.$emit('setInitialValues');
        }
    }

    private cancelForm(
        previousForm: LooseObject[],
        previousFormName: string,
        previousWorkflowName: string,
    ) {
        this.clearForm();
        this.currentFormName = previousFormName;
        this.currentWorkflowName = previousWorkflowName;
        this.children = previousForm;
        this.updateRoute();
        store.commit('updateEditAvailableFlag', true);
    }

    private populateComponentProps(comp: ComponentListModel, valueToEdit?: string | number) {
        switch (comp.ref) {
            case 'title': {
                const props = {
                    content: comp.content,
                    elementId: comp.elementId,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_button_radio':
            case 'input_radio': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || null,
                    options: comp.options,
                    value: comp.attributes?.value,
                    orientation: comp.attributes?.orientation,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_checkbox': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || null,
                    options: comp.options,
                    value: comp.attributes?.value,
                    checkAll: comp.attributes?.checkAll,
                    orientation: comp.attributes?.orientation,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'text': {
                const props = {
                    elementId: comp.elementId,
                    content: comp.content,
                    label: comp.label,
                    unit: comp.attributes?.unit,
                    currency: comp.attributes?.currency,
                    locale: comp.attributes?.locale,
                    decimals: comp.attributes?.decimals,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_submit': {
                const props = {
                    content: comp.content,
                    value: comp.attributes?.value,
                    elementId: comp.elementId,
                    editedMessageId: comp.editedMessageId,
                    isEdit: comp.isEdit,
                    previousForm: comp.previousForm,
                    previousFormName: comp.previousFormName,
                    previousWorkflowName: comp.previousWorkflowName,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'address_line':
            case 'address_city':
            case 'address_county':
            case 'address_postcode':
            case 'input_text': {
                const props = {
                    placeholder: comp.attributes?.placeholder,
                    minLength: comp.validations?.minLength,
                    maxLength: comp.validations?.maxLength,
                    pattern: comp.validations?.pattern,
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    password: comp.attributes?.password,
                    visibility: comp.attributes?.visibility,
                    textBox: comp.attributes?.textBox,
                    textBoxHeight: comp.attributes?.textBoxHeight,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_address': {
                const props = {
                    placeholder: comp.attributes?.placeholder,
                    pattern: comp.validations?.pattern,
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                    buildingName: comp.attributes?.buildingName,
                    buildingNumber: comp.attributes?.buildingNumber,
                    street: comp.attributes?.street,
                    town: comp.attributes?.town,
                    county: comp.attributes?.county,
                    country: comp.attributes?.country,
                    subBuildingName: comp.attributes?.subBuildingName,
                    subBuildingNumber: comp.attributes?.subBuildingNumber,
                    postcode: comp.attributes?.postcode,
                    provider: comp.attributes?.provider,
                    lookupType: comp.attributes?.lookupType,
                    countryLimitCode: comp.attributes?.countryLimitCode,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_sso': {
                const props = {
                    elementId: comp.elementId,
                    content: comp.content,
                    label: comp.label,
                    provider: comp.attributes?.provider,

                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_select': {
                const props = {
                    placeholder: comp.attributes?.placeholder,
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                    options: comp.options,
                    value: comp.attributes?.value,
                    searchable: comp.attributes?.searchable,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_phone': {
                const props = {
                    minLength: comp.validations?.minLength,
                    maxLength: comp.validations?.maxLength,
                    pattern: comp.validations?.pattern,
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    placeholder: comp.attributes?.placeholder,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_date': {
                const props = {
                    placeholder: comp.attributes?.placeholder,
                    format: comp.attributes?.format,
                    locale: comp.attributes?.locale,
                    minDate: comp.validations?.minDate,
                    maxDate: comp.validations?.maxDate,
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_email': {
                const props = {
                    placeholder: comp.attributes?.placeholder,
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'term_slider':
            case 'amount_slider': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    label: comp.label,
                    unit: comp.attributes?.unit,
                    locale: comp.attributes?.locale,
                    decimals: comp.attributes?.decimals,
                    currency: comp.attributes?.currency,
                    stepIncrement: comp.attributes?.stepIncrement,
                    minValue: comp.validations?.minValue,
                    maxValue: comp.validations?.maxValue,
                    ariaLabel: comp.attributes?.ariaLabel,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'income':
            case 'expense':
            case 'input_number': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    unit: comp.attributes?.unit,
                    currency: comp.attributes?.currency,
                    locale: comp.attributes?.locale,
                    decimals: comp.attributes?.decimals,
                    placeholder: comp.attributes?.placeholder,
                    minValue: comp.validations?.minValue,
                    maxValue: comp.validations?.maxValue,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_file': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    validationMessage: comp.validations?.validationMessage,
                    label: comp.label,
                    placeholder: comp.attributes?.placeholder,
                    fileName: comp.attributes?.fileName,
                    fileType: comp.attributes?.fileType,
                    maxSize: comp.validations?.maxSize,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_hidden': {
                const props = {
                    elementId: comp.elementId,
                    value: comp.attributes?.value,
                    label: comp.label,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'input_tokenex_card': {
                const props = {
                    elementId: comp.elementId,
                    label: comp.label,
                    authenticationKey: comp.attributes?.authenticationKey,
                    timestamp: comp.attributes?.timestamp,
                    tokenExId: comp.attributes?.tokenExId,
                    ariaLabel: comp.attributes?.ariaLabel,
                    placeholder: comp.attributes?.placeholder,
                    includeCvv: comp.attributes?.includeCvv,
                    cvvLabel: comp.attributes?.cvvLabel,
                    cvvPlaceholder: comp.attributes?.cvvPlaceholder,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'image': {
                const props = {
                    elementId: comp.elementId,
                    ariaLabel: comp.attributes?.ariaLabel,
                    href: comp.attributes?.href,
                    maxWidth: comp.attributes?.maxWidth,
                    maxHeight: comp.attributes?.maxHeight,
                    unit: comp.attributes?.unit,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'pdf': {
                const props = {
                    elementId: comp.elementId,
                    value: comp.attributes?.value,
                    ariaLabel: comp.attributes?.ariaLabel,
                    label: comp.label,
                    src: comp.attributes?.b64,
                    fileName: comp.attributes?.fileName,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'output_files_download':
            case 'output_files': {
                const props = {
                    elementId: comp.elementId,
                    value: comp.attributes?.value,
                    label: comp.label,
                    files: comp.files,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'output_table': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    tableColumns: comp.tableColumns,
                    actionColumns: comp.actionColumns,
                    tableData: comp.tableData,
                    label: comp.label,
                    ariaLabel: comp.attributes?.ariaLabel,
                    deleteable: comp.attributes?.deleteable,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
            case 'output_widget': {
                const props = {
                    elementId: comp.elementId,
                    required: comp.validations?.required,
                    requiredMessage: comp.validations?.requiredMessage,
                    widgets: comp.widgets,
                    label: comp.label,
                    defaultEditValue: valueToEdit || comp.attributes?.value || null,
                };
                this.children.push({ component: comp.component, props });
                break;
            }
        }
    }
}
